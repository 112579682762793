import { View } from '@scriptx-com/xtv-toolkit';
import { CommonActions, useFocusEffect } from '@react-navigation/native';
import * as React from 'react';
import { BackHandler } from 'react-native';
import Modal from '../../../application/builder/components/modal/modal';
import Pressable from '../../../application/builder/components/pressable/pressable';
import { AppContext } from '../../../application/context/appContext';
import { DeviceContext } from '../../../application/context/deviceContext';
import { UserContext } from '../../../application/context/userContext';
import utils from '../../../application/general/utils';
import { useTVRemoteHandler } from '../../../application/hooks/useTVRemoteHandler';
import lang from '../../../application/languages/languages';
import { getCorrectHeight } from '../../../application/styling/correctSizes';
import { getFontSize } from '../../../application/styling/fontSizes';
import { getRealWidth } from '../../../application/styling/realDeviceSizes';
import { sendActionReport } from '../../../reporting/apis/action';
import { sendPageReport } from '../../../reporting/apis/page';
import { actionType, pageType } from '../../../reporting/models/apps';
import ScreenWrapper from '../../wrapper';
import moment from 'moment';
import Text from '../../../application/builder/components/text';
import { getFocusStyling, getModalStyling, getPressableStyling, getPressableTextStyling } from '../../../application/builder/components/helpers/helper';
import { getQueryString } from '../../../application/app/helpers/getQueryString';
import closeApp from '../../content/home/helpers/index';

const Screen_ = ({ navigation, route, focusContext }): React.ReactElement => {
    const appContext = React.useContext(AppContext);
    const deviceContext = React.useContext(DeviceContext);
    const userContext = React.useContext(UserContext);
    const [show, setShow] = React.useState(false);
    const [showExit, setShowExit] = React.useState(false);
    const showExitSync = React.useRef(false);
    const backCount = React.useRef(0);

    const disableNativeHardwareBackPress = () => {
        return true;
    };
    React.useEffect(() => {
        const sub = BackHandler.addEventListener('hardwareBackPress', disableNativeHardwareBackPress);
        return sub.remove;
    }, []);

    useTVRemoteHandler(({ eventType, eventKeyAction }: any) => {
        if (!deviceContext.isWebTV && eventKeyAction === 'down') {
            if (['back', 'menu'].includes(eventType)) {
                backCount.current++;
                if (backCount.current == 1) {
                    setShowExit(true);
                    showExitSync.current = true;
                }
                if (backCount.current == 2) {
                    if (deviceContext.isTizen) {
                        // @ts-expect-error
                        if (typeof tizen !== 'undefined') {
                            // @ts-expect-error
                            window.tizen.application.getCurrentApplication().exit();
                        }
                    } else if (deviceContext.isWebos) {
                        // @ts-expect-error
                        if (typeof webOS !== 'undefined') {
                            // @ts-expect-error
                            webOS.platformBack();
                        }
                    } else {
                        closeApp();
                    }
                }
            } else {
                if (showExitSync.current == true) {
                    cancelExit();
                }
            }
        }
    });
    React.useEffect(() => {
        const smartTVRemoteHandler = (event) => {
            const { key, keyCode } = event;
            if (keyCode == 413 || keyCode == 10009 || keyCode == 461) {
                backCount.current++;
                if (backCount.current == 1) {
                    setShowExit(true);
                    showExitSync.current = true;
                }
                if (backCount.current == 2) {
                    if (deviceContext.isTizen) {
                        // @ts-expect-error
                        if (typeof tizen !== 'undefined') {
                            // @ts-expect-error
                            window.tizen.application.getCurrentApplication().exit();
                        }
                    } else if (deviceContext.isWebos) {
                        // @ts-expect-error
                        if (typeof webOS !== 'undefined') {
                            // @ts-expect-error
                            webOS.platformBack();
                        }
                    } else {
                        BackHandler.exitApp();
                    }
                }
            } else {
                if (showExitSync.current == true) {
                    cancelExit();
                }
            }
        };

        if (deviceContext.isTizen || deviceContext.isWebos) {
            window.addEventListener('keydown', smartTVRemoteHandler);
        }
        return () => {
            if (deviceContext.isTizen || deviceContext.isWebos) {
                window.removeEventListener('keydown', smartTVRemoteHandler);
            }
        };
    }, []);
    const cancelExit = () => {
        setShowExit(false);
        showExitSync.current = false;
        backCount.current = 0;
    };

    const selectAppLanguage = (lang: any, code: any) => {
        userContext.setSelectedLanguage(lang);
        utils.storeJson('Selected_Language', lang);

        userContext.setSelectedLanguageCode(code);
        utils.storeJson('Selected_Language_Code', code);

        userContext.setIso2(code);

        sendActionReport(appContext, deviceContext, userContext, actionType.SELECT_LANGUAGE);

        var webTvUrl = '';
        var queryStrings;
        if (deviceContext.isWebTV) {
            webTvUrl = window.location.href;
            queryStrings = getQueryString(webTvUrl) as any;
        }
        if (webTvUrl.indexOf('connect=true') > -1 || queryStrings?.connect != undefined) {
            navigation && navigation.navigate('LoginCode');
        } else if (queryStrings?.share != undefined || webTvUrl.indexOf('sharing=true') > -1) {
            navigation && navigation.navigate('Sharing');
        } else if (appContext.application.settings?.enable_onboarding == true && userContext.isOnboardingFinished == false && appContext.application?.settings.enable_anonymous != true) {
            navigation.dispatch(
                CommonActions.reset({
                    index: 1,
                    routes: [{ name: 'Onboarding' }],
                })
            );
        } else if (appContext.application.settings?.enable_nested == true && appContext.application?.settings.enable_anonymous != true) {
            navigation.dispatch(
                CommonActions.reset({
                    index: 1,
                    routes: [{ name: 'Services', params: route.params }],
                })
            );
        } else {
            if (appContext.application?.settings.enable_anonymous != true) {
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: 'Login', params: route.params }],
                    })
                );
            } else {
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: 'Anonymous' }],
                    })
                );
            }
        }
    };

    useFocusEffect(
        React.useCallback(() => {
            const start = moment().unix();
            return () => {
                let duration = moment().unix() - start;
                sendPageReport(appContext, deviceContext, userContext, pageType.LANGUAGE, duration);
            };
        }, [])
    );

    // function getOriginUrl() {
    //     var href = document.location.href;
    //     var referrer = document.referrer;
    //     // Detect if you're inside an iframe
    //     if(window.parent != window) {
    //         // Take referrer as origin
    //         return referrer;
    //     } else {
    //         // Take href
    //         return href;
    //     }
    // }
    React.useEffect(() => {
        var webTvUrl = '';
        var queryStrings;
        if (deviceContext.isWebTV) {
            var webTvUrl = window.location.href;
            queryStrings = getQueryString(webTvUrl) as any;
        }

        if (webTvUrl.indexOf('connect=true') > -1 || queryStrings?.connect != undefined) {
            navigation && navigation.navigate('LoginCode');
        } else if (queryStrings?.share != undefined || webTvUrl.indexOf('sharing=true') > -1) {
            navigation && navigation.navigate('Sharing');
        } else if (appContext.application.settings.languages.length == 1) {
            userContext.setSelectedLanguage(appContext.application.settings.languages[0].name);
            userContext.setSelectedLanguageCode(appContext.application.settings.languages[0].code);
            selectAppLanguage(appContext.application.settings.languages[0].name, appContext.application.settings.languages[0].code);
        } else if (userContext.autoLogin == true) {
            if (userContext.selectedLanguage != '' && appContext.application.settings?.enable_onboarding == true && userContext.isOnboardingFinished == false && appContext.application?.settings.enable_anonymous != true) {
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: 'Onboarding' }],
                    })
                );
            } else if (userContext.selectedLanguage != '' && appContext.application.settings?.enable_nested == true && appContext.application?.settings.enable_anonymous != true) {
                navigation.dispatch(
                    CommonActions.reset({
                        index: 1,
                        routes: [{ name: 'Services', params: route.params }],
                    })
                );
            } else if (userContext.selectedLanguage != '') {
                if (appContext.application?.settings.enable_anonymous != true) {
                    navigation.dispatch(
                        CommonActions.reset({
                            index: 1,
                            routes: [{ name: 'Login', params: route.params }],
                        })
                    );
                } else {
                    navigation.dispatch(
                        CommonActions.reset({
                            index: 1,
                            routes: [{ name: 'Anonymous' }],
                        })
                    );
                }
            } else if (appContext.application.settings.languages == undefined) {
                selectAppLanguage('English', 'en');
                userContext.setSelectedLanguage('English');
                userContext.setSelectedLanguage('en');
                if (appContext.application.settings?.enable_onboarding == true && userContext.isOnboardingFinished == false && appContext.application?.settings.enable_anonymous != true) {
                    navigation.dispatch(
                        CommonActions.reset({
                            index: 1,
                            routes: [{ name: 'Onboarding' }],
                        })
                    );
                } else if (appContext.application.settings?.enable_nested == true && appContext.application?.settings.enable_anonymous != true) {
                    navigation.dispatch(
                        CommonActions.reset({
                            index: 1,
                            routes: [{ name: 'Services', params: route.params }],
                        })
                    );
                } else {
                    if (appContext.application?.settings.enable_anonymous != true) {
                        navigation.dispatch(
                            CommonActions.reset({
                                index: 1,
                                routes: [{ name: 'Login', params: route.params }],
                            })
                        );
                    } else {
                        navigation.dispatch(
                            CommonActions.reset({
                                index: 1,
                                routes: [{ name: 'Anonymous' }],
                            })
                        );
                    }
                }
            } else {
                setShow(true);
            }
        } else {
            setShow(true);
        }
    }, [navigation, route]);

    return (
        <ScreenWrapper style={{ flex: 1 }} focusOptions={{ focusKey: 'screen' }}>
            {showExit && <Modal focusContext={focusContext} styling={getModalStyling(appContext.application.theme.modals.exit, appContext)} type={'exit'} submitChoice={cancelExit} timerTime={3} />}
            {show && (
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    {appContext.application.settings.languages.map((language: any, index: any) => {
                        return (
                            <View style={{ margin: 5, ...getPressableStyling(appContext.application.authentication.login.components?.button?._id, appContext) }}>
                                <Pressable
                                    style={{
                                        width: getRealWidth(deviceContext) * (deviceContext.isPhone || deviceContext.isPwaVertical || deviceContext.isKaiOs ? 0.8 : 0.3),
                                        height: getCorrectHeight(deviceContext, 30),
                                        ...getPressableStyling(appContext.application.authentication.language.components?.button?._id, appContext),
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    key={index}
                                    animatorOptions={getFocusStyling('Buttons', appContext)}
                                    onPress={() => selectAppLanguage(language.name, language.code)}
                                >
                                    <View>
                                        <Text
                                            style={{
                                                padding: getCorrectHeight(deviceContext, 2),
                                                fontFamily: deviceContext.fontType,
                                                fontSize: getFontSize(deviceContext, 'Normal'),
                                                ...getPressableTextStyling(appContext.application.authentication.language.components?.button?._id, appContext),
                                            }}
                                        >
                                            {lang.getLanguage(language.name)}
                                        </Text>
                                    </View>
                                </Pressable>
                            </View>
                        );
                    })}
                </View>
            )}
        </ScreenWrapper>
    );
};
export default Screen_;
